<template>
    <!-- Page: pages/agency-contacts -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container dfw mobile-contain">
        <!--span v-if="url && name">← </span><a :href="url" v-if="url && name">Back to {{ name }}</a-->

        <h1>Previous Contacts</h1>
        <userTools/>
    </div>

    <hr/>

    <div class="container">

        <general-container
            customClass="column"
            :isLocked="false"
        >

            <preloader v-if="loading"></preloader>

            <contact-line
                v-if="!loading"
                v-for="update in contacts"
                :name="getContactName(update.contact.ContactRef)"
                :agency="update.toAgencyName"
                :role="update.toPositionName"
                :url="update.contact.ContactRef.url"
                :agencyUrl="update.toAgency ? update.toAgency.AgencyRef.url : null"
                :roleUrl="update.toPosition ? update.toPosition.PositionRef.url : null"
                :key="update.contact.ContactRef.name"
                :imagePath="update.contact.ContactRef.photo ? update.contact.ContactRef.photo.url : null"
                :leadership="update.contact.ContactRef.agencyHead"
            />

            <pagination
                v-if="!loading && !(!contacts || contacts.length === 0) && pageCount > 1"
                @paginated="page = $event"
                :current="page"
                :pageCount="pageCount"
            />

            <div class="error-message" v-if="!loading && contacts.length === 0">
                <span>😬 Bummer!</span>
                <p>We couldn't find any results for that.</p>
            </div>

        </general-container>

    </div>
    <!-- End Page: pages/agency-contacts -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container";
    import InterestedInBlock from '@/alivue/components/interested-in-block.vue';
    import UserTools from "@/alivue/components/user-tools";
    import ContactInformationCard from "./contact-info-card";
    import SearchBlock from "./search-block";
    import { computed, ref, watch, reactive } from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import CustomSelect from "./custom-select";
    import Pagination from "./pagination";
    import { priceFormat } from "@/helpers/filter";
    import { contactFullName } from "@/helpers/objects";
    import ContactLine from "@/alivue/components/contact-line";
    import { useRoute } from "vue-router";

    export default {
        components: {
            Pagination,
            Alert,
            Breadcrumbs,
            GeneralContainer,
            UserTools,
            ContactInformationCard,
            SearchBlock,
            Preloader,
            CustomSelect,
            InterestedInBlock,
            ContactLine,
        },
        data() {
            return {
                contacts: [],
                positionContentId: '',
                page: 1,
                pageCount: 1,
                pageSize: 10,
                loading: true,
                url: null,
                name: null,
            };
        },
        methods: {
            loadContacts() {
                this.loading = true;
                this.contacts = [];
                let searchQuery = this.searchQuery;
                RequestHandler.loadPreviousPositionEmploymentUpdates(
                    this.positionContentId, (this.page - 1) * this.pageSize, this.pageSize,
                ).then(ContentList => {
                    this.contacts = ContentList.list;
                    this.pageCount = ContentList.pageCount;
                    this.loading = false;
                }).catch(error => {
                    console.error(error);
                    this.loading = false;
                    this.pageCount = 1;
                });
            },
            getContactName: function (contact) {
                return contactFullName(contact);
            },
        },
        mounted() {
            if (this.$route.query.positionId) {
                this.positionContentId = this.$route.query.positionId;
            }

            this.loadContacts();
        },
        watch: {
            page() {
                window.scrollTo(0, 0);
                this.loadContacts();
            },
        },
        setup(props) {
            const store = useStore();
            const contactView = ref(false);
            const showSearch = computed(() => store.getters.showSearch);
            const name = ref(null);
            const url = ref(null);

            const route = useRoute();
            const path = reactive([]);
            RequestHandler.loadPositionByContentId(route.query.positionId).then((position) => {
                //console.log(position);
                path.push({ url: position.PositionRef.url, title: position.PositionRef.name });
                name.value = position.PositionRef.name;
                url.value = position.PositionRef.url;
                path.push({ title: 'Previous Contacts' });
            });

            return {
                path,
                url,
                name,
                priceFormat,
                showSearch,
                contactView,
            };
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .error-message {
        width: 100%;
        text-align: center;
        font-size: 1.25em;

        span {
            font-size: 1.5em;
            font-weight: 600;
        }
    }
</style>
